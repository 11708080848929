const importIconsKeys = [
  "UserFilled",
  "UploadFilled",
  "Promotion",
  "Plus",
  "EditPen",
  "Delete",
  "Download",
  "Edit",
  "Upload",
  "Search",
  "Refresh",
  "VideoPlay",
  "CircleClose",
  "Memo"
]

export default importIconsKeys;