import { createStore } from 'vuex'
import { AddRouteRecordRaw } from '@/router'

export default createStore({
  state: {
    currentRoute: null
  },
  getters: {
    getCurrentRoute(state) { 
      const route:AddRouteRecordRaw = state.currentRoute;
      return {
        index: route.sort,
        title: route.meta.title,
        icon: route.meta.icon,
        name: route.name
      }
    }
  },
  mutations: {
    SET_CURRENT_ROUTE(state, route) {
      state.currentRoute = route
    }
  },
  actions: {
    setCurrentRoute(context:any, route:AddRouteRecordRaw) {
      context.commit('SET_CURRENT_ROUTE', route)
    }
  }
})
