import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-648f0256"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "app-main"
};
export function render(_ctx, _cache) {
  const _component_router_view = _resolveComponent("router-view");

  return _openBlock(), _createElementBlock("section", _hoisted_1, [_createVNode(_component_router_view, null, {
    default: _withCtx(({
      Component,
      route
    }) => [_createVNode(_Transition, {
      name: "fade-transform",
      mode: "out-in"
    }, {
      default: _withCtx(() => [(_openBlock(), _createBlock(_KeepAlive, null, [!route.meta.link ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
        key: route.path
      })) : _createCommentVNode("", true)], 1024))]),
      _: 2
    }, 1024)]),
    _: 1
  })]);
}