import { createApp } from 'vue'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import importIconsKeys from '@/components/ElementPlusIcons'
import '@/assets/styles/global.scss' // global css
import 'element-plus/es/components/message-box/style/index'
import 'element-plus/es/components/message/style/index'
import App from './App.vue'
import router from './router'
import store from './store'
import plugins from './plugins' // plugins


const app = createApp(App)

app.use(store);
app.use(router);
app.use(plugins)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  if (importIconsKeys.includes(key)) {
    app.component(key, component)
  }
}

app.mount('#app')
