import { onBeforeRouteUpdate, useRouter } from 'vue-router';
import { useStore } from "vuex";
function listeningRouter() {
  const store = useStore();
  const router = useRouter();
  // 初始化
  const currentRoute = router.currentRoute.value;
  store.dispatch('setCurrentRoute', currentRoute);
  //开始监听
  onBeforeRouteUpdate((to) => {
    store.dispatch('setCurrentRoute', to);
  });
}

export default listeningRouter;

